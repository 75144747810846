import React, { useEffect, useState } from "react";
import "../../assets/Toast.css";
import Accordion from "../../components/Accordion";
import Toast from "../../components/Toast";
import faqs from "../../data/faqs.json";
import orderForm from "../../data/orderForm.json";
import { OrderForm } from "./OrderForm";
import { handleSubmit } from "./handleSubmit";
import { validateOrderPlaced } from "./validateOrderPlaced";

function Order() {
    const [showToast, setShowToast] = useState(false);
    const [toastOptions, setToastOptions] = useState({});
    const [formValues, setFormValuesOriginal] = useState(() => {
        const storedValues = window.localStorage.getItem("formValues");
        console.log("STORED VALUES", { storedValues, type: typeof storedValues });
        return typeof storedValues === "string" ? JSON.parse(storedValues) : {};
    });

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("cancelled")) {
            setToastOptions({
                bodyText: "Payment was cancelled or invalid.",
                onClose: () => {
                    urlParams.delete("cancelled");
                    const newSearch = urlParams.toString();
                    window.history.replaceState({}, "", `${window.location.pathname}${newSearch ? "?" + newSearch : ""}`);
                },
                options: {
                    theme: "error",
                    showClose: false,
                    overlay: true,
                    cancelButton: {
                        text: "Close",
                    },
                },
            });
            setShowToast(true);
        } else if (!urlParams.has("invoice")) {
            validateOrderPlaced();
        }
    }, []);

    const setFormValues = (newFormValues) => {
        if (!newFormValues) return;
        if (JSON.stringify(newFormValues) === "{}") return;
        console.log("SET FORM VALUES", newFormValues);
        window.localStorage.setItem("formValues", JSON.stringify(newFormValues));
        setFormValuesOriginal(newFormValues);
    };

    return (
        <section className="OrderForm container">
            <div className="row">
                <div className="col-lg-6">
                    <h3>Order Form:</h3>
                    <p>{orderForm.intro}</p>
                    <OrderForm
                        onSubmit={handleSubmit}
                        orderForm={orderForm}
                        formValues={formValues}
                        setFormValues={setFormValues}
                    />
                </div>
                <div className="col-lg-6">
                    <h3>FAQ:</h3>
                    <Accordion content={faqs} />
                </div>

                <Toast visible={showToast} {...toastOptions} />
            </div>
        </section>
    );
}

export default Order;
