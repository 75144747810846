import axios from "axios";
import React, { useState } from "react";
import "../../assets/Toast.css";
import Toast from "../../components/Toast";

function Contact() {
    const [showToast, setShowToast] = useState(false);
    const [toastOptions, setToastOptions] = useState({});
    const [contactFormValues, setFormValuesOriginal] = useState(() => {
        const storedValues = window.localStorage.getItem("contactFormValues");
        return typeof storedValues === "string" ? JSON.parse(storedValues) : {};
    });

    const setFormValues = (newFormValues) => {
        if (!newFormValues) return;
        if (JSON.stringify(newFormValues) === "{}") return;
        window.localStorage.setItem("contactFormValues", JSON.stringify(newFormValues));
        setFormValuesOriginal(newFormValues);
    };

    const clearFormValues = () => {
        window.localStorage.removeItem("contactFormValues");
        setFormValuesOriginal({});
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // TODO: Send your form data to server or email service
        console.log("Form Submitted", contactFormValues);
        const result = axios.post("https://worker.anml.dev/wordcraft-studios/contact", contactFormValues, {
            headers: {
                "Content-Type": "application/json",
                "x-anml-auth": "wordcraft-studios-contact-" + contactFormValues.email,
            },
        });
        console.log("RESULT", result);

        if (result.status !== 200) {
            setToastOptions({
                bodyText: "There was an error sending your message. Please try again later.",
                options: {
                    theme: "error",
                    showClose: false,
                    okButton: {
                        text: "OK",
                    },
                },
            });
        } else {
            setToastOptions({
                bodyText: "Your message was sent successfully!",
                options: {
                    theme: "success",
                    showClose: false,
                    okButton: {
                        text: "OK",
                        onClick: () => {
                            clearFormValues();
                        },
                    },
                },
            });
        }
        setShowToast(true);
    };

    return (
        <section className="ContactForm container">
            <div className="row">
                <div className="col-12">
                    <h3>Contact Us:</h3>
                    <p>Hello! Please fill out the form below to send us a message.</p>

                    <form onSubmit={handleFormSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">Email Address: *</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                required
                                value={contactFormValues.email ?? ""}
                                onChange={(e) => setFormValues({ ...contactFormValues, email: e.target.value })}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="message">Message: *</label>
                            <textarea
                                className="form-control"
                                id="message"
                                name="message"
                                rows="4"
                                required
                                value={contactFormValues.message ?? ""}
                                onChange={(e) => setFormValues({ ...contactFormValues, message: e.target.value })}
                            ></textarea>
                        </div>

                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </form>
                </div>
                <Toast visible={showToast} {...toastOptions} />
            </div>
        </section>
    );
}

export default Contact;
