import '../assets/Samples.css';
import samples from '../data/samples.json';

const SampleDetails = ({ sample, index, serviceName }) => (
    <div className="row mb-2 ml-2">
        <h4> {serviceName} Sample {index + 1}</h4>
        <p className="sampleInstructions">Instructions</p>
        <p>{sample.instructions}</p>
        <p className="sampleContent">Content</p>
        <p>{sample.content}</p>
        <p className="sampleDrafts">Drafts</p>
        <span>
            <ol>
                {sample.drafts.map((draft, draftIndex) => (
                    <li key={draftIndex} style={{ whiteSpace: 'pre-line' }}><p>{draft}</p></li>
                ))}
            </ol>
        </span>
    </div>
);


function Samples() {
    return (
        <section className="Samples container">
            {Object.entries(samples).map(([serviceName, serviceSamples]) => (
                <div key={serviceName} className="row mb-5" id={serviceName.replace(/\s+/g, '-').toLowerCase()}>
                    <div className="row mb-3">
                        <h3 >{serviceName}</h3>
                    </div>
                    {serviceSamples.map((sample, index) => (
                        <SampleDetails key={index} sample={sample} index={index} serviceName={serviceName} />
                    ))}
                </div>
            ))}
        </section>
    );
}

export default Samples;