import constants from "../../data/constants.json";

const { paypalEmail } = constants;

export const validateOrderPlaced = () => {
    const orderData = window.localStorage.getItem("orderData");
    const storedOrderData = orderData ? JSON.parse(orderData) : null;

    if (!storedOrderData) {
        return;
    }
    console.log("storedOrderData", storedOrderData);
    const urlParams = new URLSearchParams(window.location.search);

    if (
        urlParams.get("business") === paypalEmail &&
        urlParams.get("invoice") === storedOrderData.invoice &&
        urlParams.get("amount") === storedOrderData.amount &&
        urlParams.get("currency_code") === storedOrderData.currency_code
    ) {
        alert("Thanks! We will reach out to you within 1 business day to confirm the order.");
    } else {
        alert("Payment was cancelled or invalid.");
    }
    window.localStorage.removeItem("orderData");
};
