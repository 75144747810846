import constants from "../../data/constants.json";

const { authHeaderKey, workerEndpoint } = constants;

export const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    const response = await fetch(workerEndpoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            [authHeaderKey]: process.env.AUTH_HEADER_SECRET,
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        alert("Success!");
        event.target.reset();
    } else {
        alert("Error writing data. Please try again.");
    }
};
