import React, { useState } from 'react';
import '../assets/Accordion.css';

const Accordion = ({ content }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    return (
        <div className="accordion">
            {content.map(({ title, content }, index) => (
                <div key={index} className={"accordion-item" + (activeIndex === index ? " active" : "")}>
                    <div onClick={() => setActiveIndex(activeIndex !== index ? index : null)} className="accordion-title">
                        <div>{title}</div>
                        <div>{activeIndex === index ? '-' : '+'}</div>
                    </div>
                    {activeIndex === index && <div className="accordion-content">{content}</div>}
                </div>
            ))}
        </div>
    );
};

export default Accordion;
