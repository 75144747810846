import constants from "../../data/constants.json";

const { paymentServerBase, paypalUrl, paypalEmail } = constants;
export const buildPayPalURL = ({ itemName, amount }) => {
    if (!amount || !itemName) {
        throw new Error("Missing required parameters");
    }

    const url = new URL(window.location.href);
    url.searchParams.set("cancelled", "true");
    const orderData = {
        business: paypalEmail,
        item_name: itemName,
        amount: 0,
        currency_code: "USD",
        return: window.location.href,
        cancel_return: url.href,
        notify_url: `${paymentServerBase}/payments/paypal/ipn/`,
        invoice: new Date().getTime(),
    };
    window.localStorage.setItem("orderData", JSON.stringify(orderData));

    const params = new URLSearchParams(orderData);
    const finalURL = `${paypalUrl}&${params.toString()}`;
    console.log(finalURL);
    return finalURL;
};
