import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import "./assets/style.css";
import Header from "./components/Header.js";
import LandingPage from "./components/LandingPage.js";
import Samples from "./components/Samples.js";
import samples from "./data/samples.json";
import Contact from "./pages/contact";
import Order from "./pages/order";

export const pages = {
    "/": {
        label: "Home",
        subItems: {},
        component: LandingPage,
    },
    "/order": {
        label: "Order",
        subItems: {},
        component: Order,
    },
    "/samples": {
        label: "Samples",
        subItems: samples,
        component: Samples,
    },
    "/contact": {
        label: "Contact",
        subItems: {},
        component: Contact,
    },
};

function App() {
    return (
        <div className="WordcraftStudios">
            <Router>
                <Header pages={pages} />
                <main className="WordcraftStudios-main">
                    <Routes>
                        {Object.entries(pages).map(([path, { component }]) => (
                            <Route key={path} path={path} element={React.createElement(component)} />
                        ))}
                        <Route path="*" element={<div>404 - Page Not Found</div>} />
                    </Routes>
                </main>
            </Router>
        </div>
    );
}

export default App;
