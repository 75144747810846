import { buildPayPalURL } from "./buildPayPalURL";

export const handleFormSubmit = (e, formRef) => {
    e.preventDefault();
    if (!e.target.checkValidity()) {
        e.target.reportValidity();
        return;
    }

    const serviceId = formRef.current.querySelector("#service").value;
    const servicePrice = parseFloat(formRef.current.querySelector("#service").selectedOptions[0].innerText.split("$")[1]);

    if (!servicePrice) {
        alert("Missing service price");
        throw new Error("Missing service price");
    }

    window.location.href = buildPayPalURL({ itemName: serviceId, amount: servicePrice });
};
