import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../assets/Header.css";
import DarkModeToggle from "./DarkModeToggle";

function Header({ pages }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [anchor, setAnchor] = useState(null);

    useEffect(() => {
        const goToAnchor = () => {
            if (anchor) {
                const anchorElement = document.getElementById(anchor);
                if (anchorElement) {
                    const rect = anchorElement.getBoundingClientRect();
                    const header = document.querySelector(".SiteHeader");
                    const headerHeight = parseFloat(getComputedStyle(header).height);
                    const absoluteTop = window.scrollY + rect.top;
                    window.scrollTo({ top: absoluteTop - headerHeight, behavior: "smooth" });
                    window.history.replaceState(null, null, `#${anchor}`);
                }
            } else {
                window.scrollTo({ top: 0, behavior: "auto" });
            }
        };

        setTimeout(goToAnchor, 100);
    }, [anchor]);

    return (
        <header className="SiteHeader">
            <div className="SiteHeader-branding">
                <h1>
                    <Link to="/">Wordcraft Studios</Link>
                </h1>
                <p>Where Every Word is a Work of Art</p>
            </div>
            <nav className="SiteHeader-nav">
                <DarkModeToggle />
                <ul>
                    {Object.entries(pages).map(([path, pageInfo]) => (
                        <li key={path}>
                            <Link to={path} onClick={() => setAnchor(null)}>
                                {pageInfo.label}
                            </Link>
                            {Object.keys(pageInfo.subItems).length > 0 && (
                                <ul>
                                    {Object.entries(pageInfo.subItems).map(([serviceName]) => (
                                        <li key={serviceName}>
                                            <button
                                                className="anchor-button"
                                                onClick={() => {
                                                    setAnchor(serviceName.replace(/\s+/g, "-").toLowerCase());
                                                    if (location.pathname !== "/samples") {
                                                        navigate("/samples");
                                                    }
                                                }}
                                            >
                                                {serviceName}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </nav>
        </header>
    );
}

export default Header;
