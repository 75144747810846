// Toast.js
import React, { useCallback, useEffect, useRef, useState } from "react";

const Toast = ({ headerText, bodyText, onClose, visible, options = {} }) => {
    const { theme = "", showClose = true, autoClose = false, okButton, cancelButton, overlay = false } = options;
    const [show, setShow] = useState(false);
    const title = headerText || (options?.theme ? options?.theme.charAt(0).toUpperCase() + options?.theme.slice(1) : "Info");
    const okButtonRef = useRef(null);
    const cancelButtonRef = useRef(null);

    const handleClose = useCallback(() => {
        setShow(false);
        typeof onClose === "function" && onClose();
    }, [onClose]);

    const handleOk = () => {
        typeof okButton?.onClick === "function" && okButton.onClick();
        handleClose();
    };

    const handleCancel = () => {
        typeof cancelButton?.onClick === "function" && cancelButton.onClick();
        handleClose();
    };

    useEffect(() => {
        if (autoClose && visible) {
            const timer = setTimeout(() => {
                handleClose();
            }, autoClose);
            return () => clearTimeout(timer);
        }
    }, [autoClose, visible, handleClose]);

    useEffect(() => {
        setShow(visible);
        if (visible) {
            requestAnimationFrame(() => {
                if (okButtonRef.current) {
                    okButtonRef.current.focus();
                } else if (cancelButtonRef.current) {
                    cancelButtonRef.current.focus();
                }
            });
        }
    }, [visible]);

    return (
        <div className={`toast-container ${theme} ${show ? "show" : ""}`}>
            {overlay && <div className="overlay"></div>}
            <div className={`toast`}>
                <div className="toast-header">
                    <strong className="mr-auto">{title}</strong>
                    {showClose && (
                        <button type="button" className="close" onClick={handleClose}>
                            <img
                                src="https://cdn3.iconfinder.com/data/icons/flat-pro-basic-set-1-1/32/close-window-512.png"
                                width="18"
                                height="18"
                                alt="Close"
                            />
                        </button>
                    )}
                </div>
                <div className="toast-body">{bodyText}</div>
                <div className="toast-footer">
                    {okButton && (
                        <button type="button" className="btn-ok" onClick={handleOk} ref={okButtonRef}>
                            {okButton.text}
                        </button>
                    )}
                    {cancelButton && (
                        <button type="button" className="btn-cancel" onClick={handleCancel} ref={cancelButtonRef}>
                            {cancelButton.text}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Toast;
